/* eslint eqeqeq: 0 */
/* eslint react-hooks/exhaustive-deps: 0 */
/* no-mixed-operators: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Field } from "redux-form";

import Form from "../../../../common/form/Form";
import FormHeader from "../../../../common/form/FormHeader";
import FormContent from "../../../../common/form/FormContent";

import GenericPickerInput from "../../../../common/input/GenericPickerInput";
import Input from "../../../../common/input/Input";
import Button from "../../../../common/button/Button";

import {
  getIndicadores,
  salvaFiltrosPedidos,
  getPedidos,
  loadFilterFormPedidos,
} from "../../../../../actions/pedidos/PedidosAction";

import {
  getOperacoes,
  selectOperacaoNoForm,
} from "../../../../../actions/operacao/OperacaoAction";
import { Multiselect } from "react-widgets";
import { createNumberMask } from "redux-form-input-masks";
import { getSugestaoPatrimonio } from "../../../../../actions/patrimonio/PatrimonioAction";
import { calculateDate } from "../../../../../utils/helpers/DateTimeHelper";
import { Link } from 'react-router-dom';
import { NOVO_ROUTE_PATH, PEDIDOS_ROUTE_PATH } from "../../../../common/routers/constantesRoutes";

let PedidosListFilter = (props) => {
  const {
    getPedidos,
    loadFilterFormPedidos,
    getIndicadores,
    salvaFiltrosPedidos,
    pedidosConsult,
  } = props;
  
  const [idsOperacoes, setIdsOperacoes] = React.useState(pedidosConsult?.idsOperacoes || null);
  const [idsPatrimonioSeparados, setIdsPatrimonioSeparados] = React.useState(pedidosConsult?.idsPatrimonioSeparados || null);

  const [idsSolicitantes, setIdsSolicitantes] = React.useState(pedidosConsult?.idsSolicitantes || null);
  const [idsTipoItens, setIdsTipoItens] = React.useState(pedidosConsult?.idsTipoItens || null);
  const [idsTipoDespesas, setIdsTipoDespesas] = React.useState(pedidosConsult?.idsTipoDespesas || null);
  const [idsTipoPagamentos, setIdsTipoPagamentos] = React.useState(pedidosConsult?.idsTipoPagamentos || null);
  const [idsFornecedores, setIdsFornecedores] = React.useState(pedidosConsult?.idsFornecedores || null);
  
  const [dataPagamentoInicio, setDataPagamentoInicio] = React.useState(pedidosConsult?.dataPagamentoInicio || calculateDate("-", 15) || "");
  const [dataPagamentoFinal, setDataPagamentoFinal] = React.useState(pedidosConsult?.dataPagamentoFinal || calculateDate("+", 15) || "");
  const [valorBrutoInicial, setValorBrutoInicial] = React.useState(pedidosConsult?.valorBrutoInicial || 0); 
  const [valorBrutoFinal, setValorBrutoFinal] = React.useState(pedidosConsult?.valorBrutoFinal || 0); 
  const [identificador, setIdentificador] = React.useState(pedidosConsult?.identificador || ""); 
  
  const [idsUltimasEtapas, setIdsUltimasEtapas] = React.useState(pedidosConsult?.status || []);

  const [counterStatus, setCounterStatus] = React.useState(0);


  const currencyFormat = {
    prefix: 'R$ ',
    suffix: '',
    decimalPlaces: 2,
    locale: 'pt-BR',
    allowEmpty: true,
  }

  const currencyMaskInicial = createNumberMask({
    ...currencyFormat,
    onChange: value => setValorBrutoInicial(value),
  })
  const currencyMaskFinal = createNumberMask({
    ...currencyFormat,
    onChange: value => setValorBrutoFinal(value),
  })

  const chooseStatus = (key) => {
    if (idsUltimasEtapas.includes(key)) {
      let newArrIds = idsUltimasEtapas.filter((elemento) => elemento !== key);
      setIdsUltimasEtapas(newArrIds);
    } else {
      setIdsUltimasEtapas([...idsUltimasEtapas, key]);
    }
  };

  const checkStatus = (key) => {
    return idsUltimasEtapas?.some((o) => o == key) ? key : "";
  };

  const selecionarStatus = () => {
    const chips = Array.from(document.querySelectorAll(".pgmt-sts"));
    let arrStatus = [];

    chips.forEach((el) => {
      if (!el.classList.contains("pe-none"))
        arrStatus.push(Number(el.dataset.status));
    });

    setIdsUltimasEtapas(arrStatus);
  };

  const cleanStatus = () => {
    let arrStatus = [];
    setIdsUltimasEtapas(arrStatus);
  };

  async function searchFilters() {
    const data = {
      idsOperacoes,
      dataPagamentoInicio,
      dataPagamentoFinal,
      idsSolicitantes,
      idsTipoItens,
      idsTipoDespesas,
      idsTipoPagamentos,
      idsFornecedores,
      valorBrutoInicial,
      valorBrutoFinal,
      status: idsUltimasEtapas,
      idsPatrimonioSeparados,
      identificador,
    };

    await getPedidos(data, pedidosConsult?.pagingConfig?.pageIndex);
    await getIndicadores(data);
  }

  React.useEffect(() => {
    const fetchData = async () => {
      await loadFilterFormPedidos();
      searchFilters();
    };
  
    fetchData();
  }, []);

  // prettier-ignore
  React.useEffect(() => {
    salvaFiltrosPedidos({
      idsOperacoes,
      dataPagamentoInicio,
      dataPagamentoFinal,
      idsSolicitantes,
      idsTipoItens,
      idsTipoDespesas,
      idsTipoPagamentos,
      idsFornecedores,
      valorBrutoInicial,
      valorBrutoFinal,
      status: idsUltimasEtapas,
      idsPatrimonioSeparados,
      identificador,
    })

    if (idsUltimasEtapas) {
      const chips = Array.from(document.querySelectorAll(".pgmt-sts"));
      let countBlocked = 0;

      chips.forEach((el) => {
        if (el.classList.contains("pe-none")) countBlocked++;
      })

      setCounterStatus(8 - countBlocked);
    }

  }, [idsOperacoes, dataPagamentoInicio, dataPagamentoFinal, idsSolicitantes, idsTipoItens,
    idsTipoDespesas, idsTipoPagamentos, idsFornecedores, idsUltimasEtapas, valorBrutoInicial,
    valorBrutoFinal, idsPatrimonioSeparados]);

  return (
    <div className="container-fluid">
      <Form>
        <FormHeader title={"Pedidos de Compra"} />
        <FormContent
          classes="shadow-custom rounded-12 bg-white px-3 py-2"
          handleSubmit={getPedidos}
        >
          <div className="row w-100 align-items-start mx-0 mt-2">
            <div className="col-12 col-xl-4 align-self-start px-0 pr-xl-3 mb-xl-0 mb-3">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                  Patrimônio Separado
                </label>
                <GenericPickerInput
                  loadData={props.getSugestaoPatrimonio}
                  options={props.patrimoniosOptions}
                  activeClassName="inputPgmt"
                  formName={"pedidosForm"}
                  fieldName={"patrimonio"}
                  isMulti={true}
                  placeholder={"Pesquisar operação ex.:CRI.1.SEC.2"}
                  loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                  noOptionsMessage="Nenhum registro encontrado"
                  value={idsPatrimonioSeparados}
                  selectedOptionForm={(e) => setIdsPatrimonioSeparados(e)}
                />
              </div>
            </div>
            <div className="col-12 col-xl-4 align-self-start px-0 pr-xl-3 mb-xl-0 mb-3">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p lh-normal opacity-25 text-dark">
                  Operação
                </label>
                <GenericPickerInput
                  loadData={props.getOperacoes}
                  options={props.options}
                  activeClassName="inputPgmt"
                  formName={"PedidosForm"}
                  fieldName={"operacao"}
                  isMulti={true}
                  placeholder={"Pesquisar operação ex.:CRI.1.SEC.2"}
                  loadingMessage="Informe ao menos 3 caracteres para pesquisar"
                  noOptionsMessage="Nenhum registro encontrado"
                  value={idsOperacoes}
                  selectedOptionForm={(e) => setIdsOperacoes(e)}
                />
              </div>
            </div>
            <div className="col-12 col-xl-2 align-self-start px-0 pr-xl-3">
              <Field
                component={Input}
                outerClassName={"my-3"}
                className={"filter-input"}
                inputId="dataPagamentoInicio"
                type={"date"}
                name="dataPagamentoInicio"
                label="Data Início (Pagamento)"
                value={dataPagamentoInicio}
                onChange={(e) => setDataPagamentoInicio(e.target.value)}
              />
            </div>
            <div className="col-12 col-xl-2 align-self-start px-0">
              <Field
                component={Input}
                outerClassName={"my-3"}
                className={"filter-input"}
                inputId="dataPagamentoFinal"
                type={"date"}
                name="dataPagamentoFinal"
                label="Data Final (Pagamento)"
                value={dataPagamentoFinal}
                onChange={(e) => setDataPagamentoFinal(e.target.value)}
              />
            </div>
          </div>

          <div className="row w-100 align-items-start mx-0">
            <div className="col-12 col-xl align-self-start px-0 pr-xl-3">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p mt-2p lh-normal opacity-25 text-dark">
                  Solicitante
                </label>
                <Multiselect
                  value={idsSolicitantes}
                  onChange={(e) => setIdsSolicitantes(e)}
                  textField={"descricao"}
                  messages={{
                    emptyList: "Lista vazia",
                    emptyFilter: "Nenhum registro encontrado",
                  }}
                  placeholder={"Todos"}
                  filter={"contains"}
                  data={props.solicitanteList}
                />
              </div>
            </div>
            <div className="col-12 col-xl align-self-start px-0 pr-xl-3">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p mt-2p lh-normal opacity-25 text-dark">
                  Tipo de item
                </label>
                <Multiselect
                  value={idsTipoItens}
                  onChange={(e) => setIdsTipoItens(e)}
                  textField={"value"}
                  messages={{
                    emptyList: "Lista vazia",
                    emptyFilter: "Nenhum registro encontrado",
                  }}
                  placeholder={"Todas"}
                  filter={"contains"}
                  data={props.tipoItemList}
                />
              </div>
            </div>
            <div className="col-12 col-xl align-self-start px-0 pr-xl-3">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p mt-2p lh-normal opacity-25 text-dark">
                  Tipo de Despesa
                </label>
                <Multiselect
                  value={idsTipoDespesas}
                  onChange={(e) => setIdsTipoDespesas(e)}
                  textField={"descricao"}
                  messages={{
                    emptyList: "Lista vazia",
                    emptyFilter: "Nenhum registro encontrado",
                  }}
                  placeholder={"Todas"}
                  filter={"contains"}
                  data={props.tipoDespesaList}
                />
              </div>
            </div>
            <div className="col-12 col-xl align-self-start px-0">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p mt-2p lh-normal opacity-25 text-dark">
                  Tipos de Pagamento
                </label>
                <Multiselect
                  value={idsTipoPagamentos}
                  onChange={(e) => setIdsTipoPagamentos(e)}
                  textField={"value"}
                  messages={{
                    emptyList: "Lista vazia",
                    emptyFilter: "Nenhum registro encontrado",
                  }}
                  placeholder={"Todas"}
                  filter={"contains"}
                  data={props.tipoPagamentoList}
                />
              </div>
            </div>
          </div>

          <div className="row w-100 align-items-start mx-0">
            <div className="col-12 col-xl align-self-start px-0 pr-xl-3">
              <Field
                type="tel"
                component={Input}
                allowNegative={false}
                name="valorBrutoInicial"
                {...currencyMaskInicial}
                value={valorBrutoInicial}
                inputId="valorBrutoInicial"
                label="Valor Bruto (Inicial)"
              />
            </div>
            <div className="col-12 col-xl align-self-start px-0 pr-xl-3">
              <Field
                type="tel"
                component={Input}
                allowNegative={false}
                name="valorBrutoFinal"
                {...currencyMaskFinal}
                value={valorBrutoFinal}
                inputId="valorBrutoFinal"
                label="Valor Bruto (Final)"
              />
            </div>
            <div className="col-12 col-xl align-self-start px-0 pr-xl-3">
              <div className="d-flex flex-column">
                <label className="f-10-5 mb-2p mt-2 lh-normal opacity-25 text-dark">
                  Fornecedor
                </label>
                <Multiselect
                  value={idsFornecedores}
                  onChange={(e) => setIdsFornecedores(e)}
                  textField={"descricao"}
                  messages={{
                    emptyList: "Lista vazia",
                    emptyFilter: "Nenhum registro encontrado",
                  }}
                  placeholder={"Todas"}
                  filter={"contains"}
                  data={props.fornecedorList}
                />
              </div>
            </div>
            <div className="col-12 col-xl align-self-start px-0 mt-7px">
              <Field
                component={Input}
                outerClassName={"my-3"}
                className={"filter-input"}
                inputId="identificador"
                name="identificador"
                label="Identificador"
                value={identificador}
                onChange={(_e, v) => setIdentificador(v)}
              />
            </div>
          </div>

          {props.statusList.length > 0 && (
            <div className="row w-100 align-items-center mx-0 mb-2 mt-2 pl-0 pr-3">
              <span className="d-lg-block d-none col-lg-auto fw-500 f-12 pl-0 mb-1">
                Status
              </span>
              <div className="px-0 col-12 col-lg-11 w-100 d-flex flex-row flex-wrap align-items-center">
                {props.statusList.map((sts, index) => {
                  return (
                    <div
                      key={`sts-${index}`}
                      className="col-auto mb-1 px-2 tr-status"
                    >
                      <div
                        role="button"
                        onClick={() => chooseStatus(sts.key)}
                        className="d-flex flex-row align-items-center td-status prevent-select"
                      >
                        <div
                          data-status={sts.key}
                          className={`pgmt-sts circle-status sts-pd-${checkStatus(sts.key)} rounded-circle mr-1`}
                        ></div>
                        <p className="mb-0 mt-1px">{sts.value}</p>
                      </div>
                    </div>
                  );
                })}

                {idsUltimasEtapas.length < counterStatus && (
                  <span
                    onClick={selecionarStatus}
                    role="button"
                    className="text-underline p-color-lighten mb-xl-1 ml-2"
                  >
                    Selecionar todos
                  </span>
                )}
                {idsUltimasEtapas.length == counterStatus && (
                  <span
                    onClick={cleanStatus}
                    role="button"
                    className="text-underline p-color-lighten mb-xl-1 ml-2"
                  >
                    Desmarcar todos
                  </span>
                )}
              </div>
            </div>
          )}

          <div className="row w-100 mx-0">
            <div className="col-xl-2 col-12 px-0 pr-xl-3">
              <Button
                activeClassName="w-100"
                action={() => searchFilters()}
                classes="p-bg-imp rounded btn-search h-38 mt-3 w-100 d-flex justify-content-center align-items-center"
                nameClass="text-capitalize f-14"
                name={"Buscar"}
              />
            </div>
            <div className="col-xl-2 col-12 px-0">
              <Link to={`/${PEDIDOS_ROUTE_PATH}/${NOVO_ROUTE_PATH}`}>
                <Button
                  activeClassName="w-100"
                  classes="darkblue-bg-imp rounded btn-search h-38 mt-3 w-100 d-flex justify-content-center align-items-center"
                  icon="plus-circle mr-2"
                  nameClass="mt-2px text-capitalize f-14"
                  name={"Criar Registro"}
                />
              </Link>
            </div>
          </div>

        </FormContent>
      </Form>
    </div>
  );
};

PedidosListFilter = reduxForm({ form: "PedidosForm" })(PedidosListFilter);

const mapStateToProps = (state) => ({
  options: state.operacaoReducer.operacoes,
  patrimoniosOptions: state.patrimonioReducer.patrimoniosOptions,
  pedidosConsult: state.pedidosReducer.pedidosConsultCurrent,

  fornecedorList: state.pedidosReducer.pedidosFormTypesFilter.fornecedorList || [],
  tipoPagamentoList: state.pedidosReducer.pedidosFormTypesFilter.tipoPagamentoList || [],
  solicitanteList: state.pedidosReducer.pedidosFormTypesFilter.solicitanteList || [],
  statusList: state.pedidosReducer.pedidosFormTypesFilter.statusList || [],
  tipoDespesaList: state.pedidosReducer.pedidosFormTypesFilter.tipoDespesaList || [],
  tipoItemList: state.pedidosReducer.pedidosFormTypesFilter.tipoItemList || [],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPedidos,
      loadFilterFormPedidos,
      getIndicadores,
      salvaFiltrosPedidos,
      getOperacoes,
      getSugestaoPatrimonio,
      selectOperacaoNoForm,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PedidosListFilter);
